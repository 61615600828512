html {
  box-sizing: border-box;
  margin: 0;
  scroll-behavior: smooth;
}

html, body, #root {
  min-height: 100vh;
}

*{
  margin: 0px;
  padding: 0px;
}

body{
  font-family: 'Exo', sans-serif;
  overflow-x: initial;
}

