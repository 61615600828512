.about__div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.about__div > img {
    width: 20vw;
}


.about__div > p {
    width: 30vw;
    font-size: 1rem;
    white-space: break-spaces;
    text-align: justify;
    padding: 1rem;
    color: white;
}

@media (max-width: 900px) {
    .about__div {
        flex-direction: column;
    }

    .about__div > p {
        width: 90vw;
    }
}

@media (max-width: 400px) {
    .about__div > p {
        font-size: 0.8rem;
    }
}