.title__name, .title__dev {
    background-color: black;
    width: 50vw;
    height: 5vh;
    padding: 0.5rem;
    transform: skewX(-15deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title__name > h1 {
    font-size: 2rem;
    transform: skewX(15deg);
}


.title__dev > h2 {
    font-size: 1.5rem;
    transform: skewX(15deg);
}

@media (max-width: 1100px) {
    .title__name, .title__dev {
        width: 80vw;
    }
}