.skills {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    list-style: none;
    padding-inline-start: 0;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin: 1.5rem;
}

.skills__title {
    padding-right: 1rem;
    color: white;
    font-weight: 800;
    font-size: calc(16px + 2vw);
}

.skills__div {
    height: calc(10px + 2vw);
    aspect-ratio: 1/1;
    background-color: black;
    padding: 0.5rem;
    border-radius: 30%;
    cursor:pointer;
    border: 3px solid white;
}

.skills__div > p {
    color: white;
    position: absolute;
    font-size: 0.5rem;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.skills__div > img {
    height: 100%;
    object-fit: contain;
    object-position: center;
}
