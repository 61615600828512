.swiper {
    aspect-ratio: 2/1;
    height: calc(20rem + 10vw);
    margin: 3rem auto;
}

.swiper-wrapper {
    box-sizing: border-box;
    padding-bottom: 2.5rem ;
}

.swiper-slide {
    height: 100%;
    width: 60vw;
    border-radius: 10px;
}

.swiper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.projects-name {
    color: white;
    font-weight: 600;
    position: absolute;
    text-shadow: 2px 2px 2px black;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: -40px;
}

.code-link, .website-link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    font-weight: 600;
    width: 5rem;
    height: 2rem;
    background-color: rgb(1, 198, 198);
    border: 3px solid black;
    padding: 0.25rem 0;
    border-radius: 10px;
    text-decoration: none;
    bottom: 1%;
}

.code-link {
    left: 50px;
}

.website-link {
    right: 50px;
}

.swiper-button-prev, .swiper-button-next {
    color: white;
}

@media (max-width: 800px) {
    .swiper {
        height: auto;
        margin: 3rem auto;
    }

}

@media (max-width: 500px) {
    .code-link, .website-link {
        padding: 0;
        border: 2px solid black;
        width: 3rem;
    }
}