.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-button__div {
    height: 7vh;
    max-width: 1200px;
    width: 50vw;
    cursor: pointer;
}

.menu-button__div:hover {
    filter: invert(1);
    z-index: 1000;
}

.menu-button__div-img {
    height: 100%;
    width: 100%;
    position: relative;
}


.menu-button__div-img > img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.menu-button__div p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ;
    color: white;
    font-weight: 700;
}

@media (max-width: 700px) {
   .menu-button__div {
       width: 90vw;
   } 
}